@import "~@csstools/normalize.css";
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700;800&display=swap");

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: "Open Sans", sans-serif;
  color: #58595b;
  @media (max-width: 961px) {
    &.menu-open {
      overflow: hidden;
      height: 100vh;
    }
  }
}


#root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}
